import { useState, useEffect } from "react";
import Controls from "@components/Controls";
import ImageDisplay from "@components/ImageDisplay";
import Gallery from "@components/Gallery";

import { useStore } from "@state/store";
import srcArr from "@data/images";
import { cacheImages } from "./utils/cacheImages";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import DefaultLayout from "./DefaultLayout";

function App() {
  const tourStyle = useStore((s) => s.tourStyle);
  const setLoading = useStore((s) => s.setLoading);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
