import React from "react";

const Landing = React.lazy(() => import("@pages/Landing"));
const Static = React.lazy(() => import("@pages/Static"));
const Page = React.lazy(() => import("@pages/Page"));
const ErrorMessage = React.lazy(() => import("@pages/ErrorMessage"));

const routes = [
  {
    name: "Landing",
    path: "/",
    exact: true,
    element: Landing,
  },
  {
    name: "Static",
    path: "/static",
    exact: true,
    element: Static,
  },
  {
    name: "Page",
    path: "/page",
    exact: true,
    element: Page,
  },
  { path: "/*", name: "Not Found", element: ErrorMessage },
];

export default routes;
