import Controls from "@components/Controls/Controls";
import Content from "./Content";

function DefaultLayout() {
  return (
    <>
      <Controls />
      <Content />
      {/* <video autoPlay muted playsInline src={"/reception_1.mp4"} /> */}
    </>
  );
}

export default DefaultLayout;
