import styled from "styled-components";

import { useStore } from "@state/store";

function Controls() {
  const setTourStyle = useStore((s) => s.setTourStyle);
  const tourStyle = useStore((s) => s.tourStyle);

  function handleStyleClick(n) {
    setTourStyle(n);
  }
  return (
    <Wrapper>
      <Button
        className={tourStyle === "Reception" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("Reception");
        }}
      >
        Reception
      </Button>
      <Button
        className={tourStyle === "Workspace" ? "selected" : ""}
        onClick={() => {
          handleStyleClick("Workspace");
        }}
      >
        Workspace
      </Button>
    </Wrapper>
  );
}

export default Controls;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .selected {
    opacity: 1;
  }
`;

const Button = styled.button`
  all: unset;
  text-align: center;
  width: 9rem;
  height: 3rem;
  background-color: #101728;
  color: #f2ecec;
  /* border: 0.2rem solid black; */
  font-family: title;
  font-size: 1.2rem;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.8s ease-in-out;
  min-width: clamp(120px, 10vw, 50rem);
  min-height: clamp(30px, 3vw, 4rem);
  margin: 2rem;
  font-size: clamp(10px, 1.6vw, 2rem);
`;
